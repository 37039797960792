<template>
  <div class="wrapper">
    <ad-details-card
      ref="AdCard"
      v-if="dataFetched"
      :adPublished="originalAd.visible"
      :disabled="!editMode"
      :isCollapsed="false"
      v-model="adObj"
      @media-edit="mediaEdited"
    >
      <div slot="actions">
        <el-button
          type="info"
          size="mini"
          v-if="!editMode"
          @click="editMode = true"
          >{{ $t("handleAdPageButtonLabelEdit") }}</el-button
        >
        <template v-else>
          <el-button type="primary" size="mini" @click="saveAd">{{
            $t("handleAdPageButtonLabelSave")
          }}</el-button>
          <el-button type="danger" size="mini" button @click="cancelEdit">{{
            $t("GlobalsCancelBtn")
          }}</el-button>
        </template>
      </div>
    </ad-details-card>
    <a style="display: none" ref="sheet-download" target="_blank"></a>
  </div>
</template>

<script>
import { MarktingService } from "../../../services";
import { utils } from "../../../utils";
import AdDetailsCard from "./AdDetailsCard.vue";

export default {
  components: { AdDetailsCard },
  data() {
    return {
      editMode: false,
      dataFetched: false,
      adObj: {
        name: "",
        imageUrl: "",
        sectionType: "section",
        isSaved: false,
        regionsList: [],
        regions: [],
        areas: [],
        type: "",
        areasList: [],
        ad_config: {},
      },
      originalAd: {},
    };
  },
  computed: {
    adId() {
      return this.$route.params.id;
    },
  },
  created() {
    this.getSingleAd();
  },
  methods: {
    convertToUnixTimestamp(dateString) {
      var date = new Date(dateString);
      var unixTimestamp = date.getTime();
      return unixTimestamp;
    },
    async getSingleAd() {
      try {
        const resData = await MarktingService.ads.getSingleAd({
          id: this.adId,
        });
        const adObj = { ...resData.data.data.result[0] };
        Object.assign(this.adObj, adObj);
        // to fix backend wrong format

        if (this.adObj.type == "banner") {
          if (!this.adObj.navigation && !this.adObj.navigationType) {
            this.adObj.navigationType = "none";
          }

          this.adObj.startDate = this.convertToUnixTimestamp(
            this.adObj.startDate
          );
          this.adObj.endDate = this.convertToUnixTimestamp(this.adObj.endDate);
          this.adObj.placement = this.adObj?.placementType;
          this.adObj.navigation = this.adObj?.navigationType;
          this.adObj.targeting =
            this.adObj?.areas?.length > 0 ? "areaTargeting" : "user";
          this.adObj.mediaType = "image";
          this.adObj["imageUrl"] = adObj?.url;
          this.adObj.areas = adObj?.areas?.map((area) => area);

          if (this.adObj?.actionButtonLabel) {
            this.adObj.isCallToAction = true;
            this.adObj.btnName = this.adObj?.actionButtonLabel;
          }
          if (this.adObj?.placementType === "provider") {
            this.adObj.providerId = +this.adObj?.placementValue;
          }
          if (this.adObj?.placementType === "section") {
            this.adObj.sections = this.adObj?.placementValue;
          }
          if (
            this.adObj?.placementType === "" ||
            this.adObj?.placementType === "home"
          ) {
            this.adObj.placement = "home";
          }
          if (
            this.adObj?.navigationType === "" ||
            this.adObj?.navigationType === "deep_link"
          ) {
            this.adObj.navigation = "deep_link";
            this.adObj.deep_link = this.adObj?.navigationValue;
          }
          if (this.adObj?.navigationType === "section") {
            this.adObj.sectionsNavigation = this.adObj?.navigationValue;
          }
          if (this.adObj?.navigationType === "provider") {
            this.adObj.navigationProvideId = +this.adObj?.navigationValue;
          }

          if (this.adObj.providerId) {
            this.adObj.sectionType = "provider";
          }
        } else if (adObj.type == "popup") {
          let mediaType = utils.getMediaTypeFromURL(this.adObj.uri);
          this.adObj.mediaType = mediaType;
          this.adObj[`${mediaType}Url`] = adObj.uri;
          this.adObj.regions = adObj?.regions?.map((region) => region.id);
          this.adObj.areas = adObj?.areas?.map((area) => area.id);
        }

        this.adObj.visible = this.adObj.isActive == 1 ? true : false;
        this.originalAd = { ...resData.data };
        this.originalAd.visible = this.adObj.isActive == 1 ? true : false;

        this.dataFetched = true;
      } catch (error) {
        console.log(error);
      }
    },
    async mediaEdited({ newUri, ad }) {
      const mediaType = newUri.type.split("/")[0];
      const fileName = newUri.name;
      if (mediaType != ad.mediaType) {
        this.$message({
          message: this.$t("CreateAdPageEditMeidaErr", [
            mediaType,
            ad.mediaType,
          ]),
          type: "error",
        });
        return;
      }

      let imageUploadFD = new FormData();
      imageUploadFD.append("image", newUri);
      try {
        const response = await MarktingService.ads.bulkUploadMedias(
          imageUploadFD
        );
        this.$message({
          message: this.$t("CreateAdPageEditMediaMsg"),
          type: this.$t("globalsSuccessTitle"),
        });
        this.adObj[`${ad.mediaType}Url`] = response.data[0];
        this.adObj.name = fileName;
      } catch (error) {
        console.log(error);
      }
    },
    async saveAd() {
      const valid = await this.$refs.AdCard.$refs["AdForm"].validate();
      const getPlacementValue = (ad) => {
        if (ad.placement === "provider") return ad.providerId;
        if (ad.placement === "section") return ad.sections;
        if (ad.placement === "home") return "home";
      };
      const getNavigationValue = (ad) => {
        if (ad.navigation === "provider") return ad.navigationProvideId;
        if (ad.navigation === "section") return ad.sectionsNavigation;
        if (ad.navigation === "deep_link") return ad.deep_link;
        if (ad.navigation === "none") return undefined;
      };
      const checkActionBtnLabel = (ad) => {
        if (ad.isCallToAction) return ad.btnName;
        else return undefined;
      };
      if (valid) {
        const adObject = {
          startDate: this.adObj.startDate,
          endDate: this.adObj.endDate,
          name: this.adObj.name,
          url:
            this.adObj.mediaType == "image"
              ? this.adObj.imageUrl
              : this.adObj.videoUrl,
          // isActive: ad.visible,
          placementType: this.adObj.placement,
          placementValue: getPlacementValue(this.adObj),
          navigationType:
            this.adObj.navigation === "none"
              ? undefined
              : this.adObj.navigation,
          navigationValue: getNavigationValue(this.adObj),
          actionButtonLabel: checkActionBtnLabel(this.adObj),
          areas:
            this.adObj.targeting === "areaTargeting" &&
            this.adObj.areas.length > 0
              ? this.adObj.areas
              : undefined,
          type: this.adObj.type,
        };
        // const adObject = {
        //   name: this.adObj.name,
        //   uri:
        //     this.adObj.mediaType == "image"
        //       ? this.adObj.imageUrl
        //       : this.adObj.videoUrl,
        //   areas: this.adObj.areas,
        //   regions: this.adObj.regions,
        //   sections: this.adObj.sections,
        //   visible: this.adObj.visible,
        //   type: this.adObj.type,
        //   providerId: this.adObj.providerId || undefined,
        //   destination: this.adObj.providerId || undefined,
        //   ad_config: this.adObj.ad_config,
        // };
        try {
          await MarktingService.ads.editAd({ id: this.adId }, adObject);
          if (this.adObj.entitiesExcelFileList.length > 0) {
            await this.handleSheetImport(
              this.adObj.entitiesExcelFileList[0],
              this.adId
            );
          }
          this.$notify({
            title: "Success",
            message: "Ad updated Successfully",
            type: "success",
          });
          this.editMode = false;
          this.originalAd = JSON.parse(JSON.stringify(this.adObj));
        } catch (error) {
          this.$notify({
            title: "Create Error",
            message: error.response.data.message,
            type: "Error",
          });
        }
      }
    },
    async handleSheetImport(file, adId) {
      let toBeSentForm = new FormData();
      toBeSentForm.append("file", file.raw);
      try {
        const response = await MarktingService.ads.importEntitiesSheet(
          toBeSentForm,
          adId
        );
        if (response && response.data) {
          const responseData = response.data;
          let defaultType = "application/json; charset=utf-8";
          let responseContentType = response.headers["content-type"];
          if (defaultType === responseContentType) {
            let decodedString = String.fromCharCode.apply(
              null,
              new Uint8Array(responseData)
            );
            let parsedResponse = JSON.parse(decodedString);
            if (parsedResponse.message) {
              this.$message({
                message: parsedResponse.message,
                type: "success",
                duration: 2000,
              });
            } else {
              this.$message({
                message: "Success",
                type: "success",
                duration: 2000,
              });
            }
          } else {
            let blob = new Blob([responseData], {
              type: response.headers["content-type"],
            });
            this.$refs["sheet-download"].setAttribute(
              "href",
              window.URL.createObjectURL(blob)
            );
            this.$refs["sheet-download"].setAttribute(
              "download",
              `uploadEntitiesErrorSheet.xlsx`
            );
            this.$refs["sheet-download"].click();
            this.$message({
              message:
                "file uploaded successfully, please check the downloaded file",
              type: "success",
              duration: 4000,
            });
          }
        }
      } catch (error) {
        console.log("error :>> ", error);
        const errorMessage = this.$globalFunctions.errorMessageExtractor(error);
        this.$globalFunctions.popupMessageWrapper(errorMessage, "error", 4000);
      }
      this.sheetFileList = [];
      this.$emit("dataUpdated");
    },
    cancelEdit() {
      this.editMode = false;
      this.getSingleAd();
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  max-width: 80%;
  margin: 0 auto;
}
</style>
